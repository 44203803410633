import AuthLayout from '../../components/common/AuthLayout';
import RegistrationForm from '@/pages/RegistrationPage/components/RegistrationForm.tsx';

const RegistrationPage = () => {
  return (
    <AuthLayout>
      <RegistrationForm />
    </AuthLayout>
  );
};

export default RegistrationPage;
