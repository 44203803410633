import {
  Box,
  InputBase,
  Radio,
  RadioProps,
  styled,
  Switch,
  SwitchProps,
  TextField,
  Typography,
} from '@mui/material';

export const FormCardBox = styled(Box)(({ theme }) => ({
  background: theme.palette.gradient3,
  padding: '50px',
  borderRadius: '30px',
  marginTop: '70px',
  maxWidth: '560px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'initial',
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '25px',
  },
}));
export const FieldsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  marginTop: '25px',
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  outline: `2px solid ${theme.palette.blue1}`,
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.purple1,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundImage: 'radial-gradient(#fff,#fff 45%,transparent 0%)',
    content: '""',
  },
}));

export function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const StyledInput = styled(InputBase)<{
  active?: number;
  error?: boolean;
  password?: number;
  iconstart?: number;
}>(({ theme, active, error, password, iconstart }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 0,
    backgroundColor: '#fff',
    borderColor: error
      ? theme.palette.red1
      : active
        ? theme.palette.purple1
        : theme.palette.gray1,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    borderRadius: '12px',
    boxShadow: '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
    padding: `18px ${password ? '47px' : '20px'} 18px ${iconstart ? '64px' : '20px'}`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.purple1,
      borderRadius: '12px',
    },
  },
}));

export const StyledTextField = styled(TextField)<{
  active?: number;
}>(({ theme, active }) => ({
  '& .MuiInput-underline:after': {
    opacity: '0',
  },
  '& fieldset': {
    border: 0,
    outline: 0,
  },
  '& .MuiInputBase-root': {
    position: 'relative',
    border: 0,
    outline: 0,
    backgroundColor: '#fff',
    borderColor: active ? theme.palette.purple1 : theme.palette.gray1,
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    borderRadius: '12px',
    boxShadow: '0px 0px 6px 0px rgba(183, 189, 201, 1) inset',
    padding: `11px  20px`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      border: 0,
      outline: 0,
      borderColor: theme.palette.purple1,
      borderRadius: '12px',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: theme.spacing(3),
  },
}));

export const StyledTruncatedTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px 5px',
    transitionDuration: '300ms',
    color: theme.palette.purple1,
    '& + .MuiSwitch-track': {
      border: '1px solid',
      borderRadius: '14px',
      borderColor: theme.palette.purple1,
    },
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.purple1,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.purple1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#fff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
