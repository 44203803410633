import { useQuery } from '@tanstack/react-query';
import { fetchLocations } from '@/api/auth/fetchers.ts';

export const useLocationsQuery = (searchValue: string) => {
  return useQuery({
    queryKey: ['locations', searchValue],
    queryFn: () => fetchLocations(searchValue),
    enabled: !!searchValue.trim(),
    select: (data) => {
      return data?.map((location: any) => ({
        value: location.location_id,
        label: `${location.city}, ${location.country}`,
      }));
    },
  });
};
