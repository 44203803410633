import { UserType } from '@/types/user.ts';

export const getFormStepTitle = (
  role: UserType['role'],
  currentStep: number
) => {
  switch (role) {
    case 'Talent':
      switch (currentStep) {
        case 1:
          return 'Login Info';
        case 2:
          return 'Personal Info';
        case 3:
          return 'Talent Details';
        case 4:
          return 'Resources Label';
        default:
          return '';
      }
    case 'Hirer':
      switch (currentStep) {
        case 1:
          return 'Login Info';
        case 2:
          return 'Personal Info';
        case 3:
          return 'Company Info (if any)';
        case 4:
          return 'Company Details';
        default:
          return '';
      }
    default:
      return '';
  }
};
