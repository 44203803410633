import { BASE_URL, BasicResponse } from '@/types/common.ts';
import {
  MultipleFilesUploadResponse,
  SingleFileUploadResponse,
  UpdateProfileBody,
} from '@/api/user/types.ts';
import { apiRequest } from '@/libs/apiRequest.ts';

export const updateProfile = async (body: UpdateProfileBody) => {
  const response = await apiRequest<BasicResponse<any>>('/profile', {
    method: 'POST',
    body,
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const uploadFile = async (
  file: File
): Promise<SingleFileUploadResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await apiRequest<any>('/file', {
    method: 'POST',
    body: formData,
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const uploadFiles = async (
  files: File[]
): Promise<MultipleFilesUploadResponse> => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));

  const response = await fetch(`${BASE_URL}/files`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to upload files');
  }

  return response.json();
};
