import { ReactNode } from 'react';
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BigLogo from '@/assets/icons/big_logo.svg?react';
import AuthHeader from '@/components/common/AuthHeader';

export const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.palette.gradient1,
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const MainSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  paddingLeft: '90px',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '30px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const StyledImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  background: theme.palette.gradient11,
  padding: '0 20px',
  alignItems: 'end',
  maxWidth: '620px',
  maxHeight: '970px',
  minWidth: '570px',
  borderBottomLeftRadius: '130px',
  [theme.breakpoints.down(1100)]: {
    minWidth: '400px',
  },
}));

export const TextContent = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  marginLeft: '50px',
  marginBottom: '150px',
  position: 'relative',
  [theme.breakpoints.down(1100)]: {
    marginLeft: '30px',
  },
}));

export const StyledLogo = styled(BigLogo)(({ theme }) => ({
  [theme.breakpoints.down(1100)]: {
    width: '130px',
  },
}));

export const FormSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: '100px',
  marginRight: '70px',
  [theme.breakpoints.down(1380)]: {
    marginRight: '30px',
    marginLeft: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: '15px',
    marginLeft: '15px',
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'start',
  },
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(34%, -85%)',
  [theme.breakpoints.down(1100)]: {
    transform: 'translate(-85%, -85%)',
  },
}));
export const FormSectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '730px',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'initial',
    overflowY: 'initial',
    maxHeight: 'initial',
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  fontFamily: 'Inter',
  fontWeight: 700,
  color: '#fff',
  lineHeight: '70px',
  [theme.breakpoints.down(1100)]: {
    fontSize: '45px',
    lineHeight: '45px',
  },
}));

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <LayoutContainer>
      {downMd && <AuthHeader />}
      <ContentContainer>
        <MainSection>
          {!downMd && (
            <StyledImageBox>
              <TextContent>
                <StyledHeading>
                  Talenver, where talent meets opportunity.
                </StyledHeading>
                <LogoWrapper>
                  <StyledLogo />
                </LogoWrapper>
              </TextContent>
            </StyledImageBox>
          )}
          <FormSectionContainer>
            <FormSection>
              <ContentWrapper>{children}</ContentWrapper>
            </FormSection>
          </FormSectionContainer>
        </MainSection>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default AuthLayout;
