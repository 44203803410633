import FormInput from '@/components/forms/FormInput';
import { HirerFields } from '@/pages/RegistrationPage/components/HirerForm/index.tsx';
import { FieldsContainer } from '@/styled';
import { useFormContext } from 'react-hook-form';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';

const ThirdHirerStep = () => {
  const { control } = useFormContext<HirerFields>();

  return (
    <FieldsContainer>
      <FormInput<HirerFields>
        name="companyName"
        control={control}
        label="Company Name"
        placeholder={'Enter your company name'}
      />
      <FormAvatarUpload<HirerFields>
        name="companyLogoId"
        control={control}
        label="Company Logo"
        squared
      />
      <LocationAutoSuggest control={control} />
    </FieldsContainer>
  );
};

export default ThirdHirerStep;
