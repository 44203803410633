import { Box, FormControl, Slider, Typography } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ReactNode } from 'react';
import { StyledFormLabel } from '@/components/forms/FormLabel';

type FormSliderProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
  marks: { value: number; label: string }[];
  min: number;
  max: number;
  step?: number;
};

const FormSlider = <TSchema extends FieldValues>({
  name,
  label,
  control,
  marks,
  min,
  max,
  step = 1,
}: FormSliderProps<TSchema>) => {
  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <StyledFormLabel>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                position: 'relative',
                margin: '15px 0 0 0',
                height: '15px',
              }}
            >
              {marks.map((el, index) => (
                <Typography
                  variant={'poppins16Regular'}
                  key={el.label}
                  sx={{
                    position: 'absolute',
                    flex: 1,
                    left: `${(100 / (marks?.length - 1)) * index}%`,
                    transform: `translateX(${index === marks?.length - 1 ? '-100%' : index === 0 ? '0' : '-50%'})`,
                  }}
                >
                  {el.label}
                </Typography>
              ))}
            </Box>
            <Slider
              value={value || min}
              onChange={(_, newValue) => onChange(newValue)}
              min={min}
              max={max}
              step={step}
              valueLabelDisplay="off"
              sx={{
                color: 'purple1',
                '& .MuiSlider-thumb': {
                  backgroundColor: '#fff',
                  border: '4px solid #4D63F5',
                },
              }}
            />
            <Typography
              variant="caption"
              color="error"
              sx={{ opacity: !!error?.message ? 1 : 0 }}
            >
              {error?.message || ' '}
            </Typography>
          </Box>
        )}
      />
    </FormControl>
  );
};

export default FormSlider;
