import { FieldsContainer } from '@/styled';
import { useFormContext } from 'react-hook-form';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import FormInput from '@/components/forms/FormInput';
import { HirerFields } from '@/pages/RegistrationPage/components/HirerForm/index.tsx';
import FormDating from '@/components/forms/FormDating';

const SecondHirerStep = () => {
  const { control } = useFormContext<HirerFields>();

  return (
    <FieldsContainer>
      <FormAvatarUpload<HirerFields>
        name="photoId"
        control={control}
        label="Profile Photo"
        horizontal
      />
      <FormInput<HirerFields>
        name="firstName"
        control={control}
        label="First Name"
        placeholder={'Enter your first name'}
      />
      <FormInput<HirerFields>
        name="lastName"
        control={control}
        label="Last Name"
        placeholder={'Enter your last name'}
      />
      <FormInput<HirerFields>
        name="position"
        control={control}
        label="Current Position"
        placeholder={'Enter your current position'}
      />
      <FormDating<HirerFields> name="dating" control={control} label="Dating" />
    </FieldsContainer>
  );
};

export default SecondHirerStep;
