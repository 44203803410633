import { useFormContext } from 'react-hook-form';
import { TalentFields } from '@/pages/RegistrationPage/components/TalentForm/index.tsx';
import { FieldsContainer } from '@/styled';
import FormInput from '@/components/forms/FormInput';
import SkillsInput from '@/components/forms/FormSkills';
import FormSocials from '@/components/forms/FormSocialLinks';
import FormDating from '@/components/forms/FormDating';
import FormSelect from '@/components/forms/FormSelect';
import { AVAILABILITY_SELECT_OPTIONS } from '@/constants';

const ThirdTalentStep = () => {
  const { control } = useFormContext<TalentFields>();
  return (
    <FieldsContainer>
      <FormInput<TalentFields>
        name="position"
        control={control}
        label="Current Position"
        placeholder={'Enter your current position'}
      />
      <SkillsInput name="skills" control={control} label="Skills" />
      <FormInput<TalentFields>
        name="bio"
        control={control}
        label="About"
        multiline
        rows={4}
        placeholder={'Enter about yourself'}
      />
      <FormSocials<TalentFields>
        name="socialLinks"
        control={control}
        label="Socials"
      />
      <FormDating<TalentFields>
        name="dating"
        control={control}
        label="Dating"
      />
      <FormSelect<TalentFields>
        name="availableFor"
        control={control}
        label="Available For"
        placeholder={'Select you are available for'}
        options={AVAILABILITY_SELECT_OPTIONS}
      />
    </FieldsContainer>
  );
};

export default ThirdTalentStep;
