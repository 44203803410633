import { Box, Typography } from '@mui/material';
import RemoveButton from '@/components/common/RemoveButton';
import { StyledTruncatedTypography } from '@/styled';

type Props = {
  value: string;
  onDelete: () => void;
};

const Chip = ({ value, onDelete }: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        paddingRight: '10px',
        paddingTop: '10px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 15px',
          borderRadius: '10px',
          backgroundColor: 'gray1',
          overflow: 'hidden',
        }}
      >
        <StyledTruncatedTypography variant={'poppins16Medium'}>
          {value}
        </StyledTruncatedTypography>
      </Box>
      {onDelete && (
        <RemoveButton
          onClick={onDelete}
          sx={{ position: 'absolute', right: '0', top: '0' }}
        />
      )}
    </Box>
  );
};

export default Chip;
