import { BASE_URL } from '@/types/common.ts';
import { ApiResponse, RequestOptions } from '@/libs/types.ts';
import { useAuthStore } from '@/store/authStore.ts';

export const apiRequest = async <T>(
  endpoint: string,
  {
    method = 'GET',
    headers = {},
    body,
    params,
    withAuth = false,
  }: RequestOptions = {}
): Promise<ApiResponse<T>> => {
  try {
    const url = new URL(`${BASE_URL}${endpoint}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, String(params[key]))
      );
    }

    const authHeaders = {
      'Content-Type': 'application/json',
      ...(withAuth
        ? { Authorization: `Bearer ${useAuthStore.getState().token || ''}` }
        : {}),
    } as Record<string, string>;

    const contentTypeHeader =
      body instanceof FormData
        ? { 'Content-Type': 'multipart/form-data' }
        : { 'Content-Type': 'application/json' };

    const response = await fetch(url.toString(), {
      method,
      headers: {
        ...authHeaders,
        ...contentTypeHeader,
        ...headers,
      },
      body:
        body instanceof FormData
          ? body
          : body
            ? JSON.stringify(body)
            : undefined,
    });

    const status = response.status;
    if (!response.ok) {
      const errorText = await response.text();
      return { error: errorText, status };
    }

    const data = await response.json();
    return { data, status };
  } catch (error) {
    return { error: (error as Error).message, status: 500 };
  }
};
