import { FieldsContainer } from '@/styled';
import { Box } from '@mui/material';
import FormInput from '@/components/forms/FormInput';
import { TalentFields } from '@/pages/RegistrationPage/components/TalentForm/index.tsx';
import { useFormContext } from 'react-hook-form';
import FormAvatarUpload from '@/components/forms/FormAvatarUpload';
import FormGender from '@/components/forms/FormGender';
import FormDate from '@/components/forms/FormDate';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';

const SecondTalentStep = () => {
  const { control } = useFormContext<TalentFields>();
  return (
    <FieldsContainer>
      <FormAvatarUpload<TalentFields>
        name="photoId"
        control={control}
        label="Profile Photo"
        horizontal
      />
      <Box sx={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <FormInput<TalentFields>
          name="firstName"
          control={control}
          label="First Name"
          placeholder={'Enter your first name'}
        />
        <FormInput<TalentFields>
          name="lastName"
          control={control}
          label="Last Name"
          placeholder={'Enter your last name'}
        />
      </Box>
      <FormGender<TalentFields>
        name="gender"
        control={control}
        label={'Gender'}
      />
      <FormDate<TalentFields>
        name="dob"
        control={control}
        label={'Date of Birth'}
      />{' '}
      <LocationAutoSuggest control={control} />
    </FieldsContainer>
  );
};

export default SecondTalentStep;
