import './App.css';
import { Route, Routes } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';
import RegistrationPage from '@/pages/RegistrationPage';
import { Toaster } from 'sonner';

function App() {
  return (
    <>
      <Routes>
        <Route
          path={`/${PAGE_ROUTES.Registration}`}
          element={<RegistrationPage />}
        />
      </Routes>
      <Toaster richColors />
    </>
  );
}

export default App;
