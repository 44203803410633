import { Button, ButtonProps } from '@mui/material';
import DeleteIcon from '@/assets/icons/trash_can.svg?react';

const RemoveButton = (props: ButtonProps) => {
  return (
    <Button
      variant={'contained'}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'initial',
        minHeight: 'initial',
        width: '20px',
        height: '20px',
        padding: 0,
        ...props?.sx,
      }}
    >
      <DeleteIcon />
    </Button>
  );
};

export default RemoveButton;
