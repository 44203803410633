import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import { Box, FormControl, Switch, Typography } from '@mui/material';
import { StyledSwitch } from '@/styled';

type DatingProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
};
const FormDating = <TSchema extends FieldValues>({
  name,
  label,
  control,
}: DatingProps<TSchema>) => {
  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', marginBottom: '20px' }}
    >
      <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
        <StyledFormLabel horizontal>{label}</StyledFormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <Typography variant="poppins16Regular">Off</Typography>
                <StyledSwitch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{ 'aria-label': 'Dating toggle' }}
                />
                <Typography variant="poppins16Regular">On</Typography>
              </Box>
            );
          }}
        />
      </Box>
    </FormControl>
  );
};

export default FormDating;
