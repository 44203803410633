import { BasicResponse } from '@/types/common.ts';
import { RegistrationRequestBody } from '@/api/auth/types.ts';
import { apiRequest } from '@/libs/apiRequest.ts';

export const registerUser = async (body: RegistrationRequestBody) => {
  const response = await apiRequest<{ accessToken: string }>('/register', {
    method: 'POST',
    body,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchLocations = async (searchValue: string) => {
  const response = await apiRequest<any>('/location', {
    method: 'GET',
    params: { q: searchValue },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
