import { Button } from '@mui/material';
import FormInput from '@/components/forms/FormInput';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import { FieldsContainer } from '@/styled';
import LoginAsField from '@/components/forms/LoginAsField';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import validator from 'validator';
import { UserType } from '@/types/user.ts';
import ReCAPTCHA from 'react-google-recaptcha';
import { registerUser } from '@/api/auth/fetchers.ts';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';
import env from '@/env.ts';
import { useAuthStore } from '@/store/authStore.ts';

const siteKey = env.VITE_GCAPTHA_SITE_KEY;

export const firstRegistrationSchema = z
  .object({
    role: z.enum(['Talent', 'Hirer']),
    email: z
      .string()
      .min(1, { message: 'Email is required' })
      .refine((value) => validator.isEmail(value), {
        message: 'Invalid email address',
      }),
    password: z.string().min(8, 'Password must be at least 8 characters long'),
    confirmPassword: z.string().min(1, { message: 'Required' }),
    gRecaptchaResponse: z.string().min(1, { message: 'Required' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  });

export type FirstRegistrationFields = z.infer<typeof firstRegistrationSchema>;

const FirstRegistrationStep = () => {
  const setToken = useAuthStore((state) => state.setToken);
  const registrationMutation = useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      if (data?.accessToken) {
        setToken(data?.accessToken);
        setPrevNextStep(1);
      }
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const setPrevNextStep = useRegistrationStepsStore(
    (state) => state.setPrevNextStep
  );
  const setSelectedRole = useRegistrationStepsStore(
    (state) => state.setSelectedRole
  );

  const onRoleChange = (role: UserType['role']) => {
    setValue('role', role);
    setSelectedRole(role);
  };

  const methods = useForm<FirstRegistrationFields>({
    resolver: zodResolver(firstRegistrationSchema),
    defaultValues: {
      role: 'Talent',
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  const role = watch('role');

  const onSubmit = (data: FirstRegistrationFields) => {
    const { confirmPassword, ...filteredData } = data; // Exclude confirmPassword
    registrationMutation.mutate(filteredData);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <LoginAsField value={role} onChange={onRoleChange} />
          <FormInput<FirstRegistrationFields>
            name="email"
            control={control}
            label="Email"
            type="email"
            placeholder={'Enter your email'}
          />
          <FormInput<FirstRegistrationFields>
            name="password"
            control={control}
            label="Password"
            type="password"
            placeholder={'Enter your password'}
          />
          <FormInput<FirstRegistrationFields>
            name="confirmPassword"
            control={control}
            label="Confirm Password"
            type="password"
            placeholder={'Enter your confirm password'}
          />
          <Controller
            name={'gRecaptchaResponse'}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <ReCAPTCHA
                  sitekey={siteKey}
                  onChange={(value) => onChange(value)}
                />
              );
            }}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '100px' }}
            disabled={!isDirty || !isValid || registrationMutation.isPending}
            type={'submit'}
          >
            Next
          </Button>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default FirstRegistrationStep;
