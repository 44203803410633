import { FormCardBox } from '@/styled';
import TalentForm from '@/pages/RegistrationPage/components/TalentForm';
import HirerForm from '@/pages/RegistrationPage/components/HirerForm';
import FormStepComponent from '@/components/forms/FormStepComponent';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import FirstRegistrationStep from '@/pages/RegistrationPage/components/FirstRegistrationStep.tsx';

const RegistrationForm = () => {
  const currentStep = useRegistrationStepsStore((state) => state.currentStep);
  const selectedRole = useRegistrationStepsStore((state) => state.selectedRole);

  const isTalent = selectedRole === 'Talent';
  const isHirer = selectedRole === 'Hirer';
  return (
    <FormCardBox>
      <FormStepComponent selectedRole={selectedRole} />
      {currentStep === 1 && <FirstRegistrationStep />}
      {isTalent && <TalentForm />}
      {isHirer && <HirerForm />}
    </FormCardBox>
  );
};

export default RegistrationForm;
