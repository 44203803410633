import { z } from 'zod';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/material';
import SecondHirerStep from '@/pages/RegistrationPage/components/HirerForm/SecondHirerStep.tsx';
import ThirdHirerStep from '@/pages/RegistrationPage/components/HirerForm/ThirdHirerStep.tsx';
import FourthHirerStep from '@/pages/RegistrationPage/components/HirerForm/FourthHirerStep.tsx';
import { toast } from 'sonner';
import { updateProfile } from '@/api/user/fetchers.ts';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

const hirerSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  position: z
    .string()
    .min(3, 'position should have more than 2 characters')
    .optional(),
  dating: z.boolean().optional(),
  photoId: z.string().optional(),
  companyLogoId: z.string().optional(),
  locationId: z.string().optional(),
  companyName: z
    .string()
    .min(3, 'companyName should have more than 2 characters')
    .optional(),
  companyDescription: z
    .string()
    .min(3, 'companyDescription should have more than 2 characters')
    .optional(),
  companyIndustry: z
    .string()
    .min(3, 'companyIndustry should have more than 2 characters')
    .optional(),
  companySize: z.number().optional(),
});

export type HirerFields = z.infer<typeof hirerSchema>;

const HirerForm = () => {
  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      setPrevNextStep(1);
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.message);
    },
  });
  const currentStep = useRegistrationStepsStore((state) => state.currentStep);
  const setPrevNextStep = useRegistrationStepsStore(
    (state) => state.setPrevNextStep
  );
  const methods = useForm<HirerFields>({
    resolver: zodResolver(hirerSchema),
    defaultValues: {},
    // reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const onSubmit = (data: HirerFields) => {
    updateProfileMutation.mutate(data);
  };

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 2 && <SecondHirerStep />}
        {currentStep === 3 && <ThirdHirerStep />}
        {currentStep === 4 && <FourthHirerStep />}
        {currentStep !== 1 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '12px',
              marginTop: '100px',
              flexDirection: 'column',
            }}
          >
            <Button
              sx={{ flex: 1 }}
              onClick={() => setPrevNextStep(-1)}
              variant={'outlined'}
            >
              Back
            </Button>
            {currentStep < 4 && (
              <Button
                onClick={() => setPrevNextStep(1)}
                sx={{ flex: 1 }}
                variant={'contained'}
              >
                Next
              </Button>
            )}
            {currentStep === 4 && (
              <Button sx={{ flex: 1 }} variant={'contained'} type={'submit'}>
                Submit
              </Button>
            )}
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default HirerForm;
