import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import validator from 'validator';
import SecondTalentStep from '@/pages/RegistrationPage/components/TalentForm/SecondTalentStep.tsx';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import { Box, Button } from '@mui/material';
import ThirdTalentStep from '@/pages/RegistrationPage/components/TalentForm/ThirdTalentStep.tsx';
import FourthTalentStep from '@/pages/RegistrationPage/components/TalentForm/FourthTalentStep.tsx';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { updateProfile } from '@/api/user/fetchers.ts';
import * as Sentry from '@sentry/react';

const talentSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  gender: z.enum(['Male', 'Female', 'Other']).optional(),
  dob: z
    .string()
    .or(z.null())
    .refine(
      (value) => (!value ? true : validator.isISO8601(value, { strict: true })),
      {
        message: 'Date of birth must be in YYYY-MM-DD format',
      }
    )
    .optional(),
  position: z.string().optional(),
  skills: z.array(z.string()).optional(),
  bio: z.string().optional(),
  socialLinks: z.array(z.string()).optional(),
  dating: z.boolean().optional(),
  // dating_location: z.enum(['ONSITE', 'MOBILE', 'BOTH']).optional(),
  availableFor: z.enum(['VIDEO', 'AUDIO', 'NONE']).optional(),
  videoUrl: z
    .string()
    .optional()
    .refine((value) => (!value ? true : validator.isURL(value || '')), {
      message: 'Video URL must be a valid URL',
    }),
  photoId: z.string().optional(),
  locationId: z.string().optional(),
  mediaIds: z.array(z.string()).optional(),
});

export type TalentFields = z.infer<typeof talentSchema>;

const TalentForm = () => {
  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      setPrevNextStep(1);
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.message);
    },
  });

  const currentStep = useRegistrationStepsStore((state) => state.currentStep);
  const setPrevNextStep = useRegistrationStepsStore(
    (state) => state.setPrevNextStep
  );
  const methods = useForm<TalentFields>({
    resolver: zodResolver(talentSchema),
    defaultValues: {
      dob: null,
      gender: 'Male',
      dating: false,
    },
    // reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const onSubmit = (data: TalentFields) => {
    updateProfileMutation.mutate(data);
  };
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 2 && <SecondTalentStep />}
        {currentStep === 3 && <ThirdTalentStep />}
        {currentStep === 4 && <FourthTalentStep />}
        {currentStep !== 1 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '12px',
              marginTop: '100px',
              flexDirection: 'column',
            }}
          >
            <Button
              sx={{ flex: 1 }}
              onClick={() => setPrevNextStep(-1)}
              variant={'outlined'}
            >
              Back
            </Button>
            {currentStep < 4 && (
              <Button
                onClick={() => setPrevNextStep(1)}
                sx={{ flex: 1 }}
                variant={'contained'}
              >
                Next
              </Button>
            )}
            {currentStep === 4 && (
              <Button
                disabled={updateProfileMutation.isPending}
                sx={{ flex: 1 }}
                variant={'contained'}
                type={'submit'}
              >
                Submit
              </Button>
            )}
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default TalentForm;
