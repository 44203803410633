import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Box, FormControl, styled } from '@mui/material';
import { StyledInput } from '@/styled';
import CheckCircle from '@/assets/icons/check_circle.svg?react';
import React from 'react';
import FacebookIcon from '@/assets/icons/facebook_icon.svg?react';
import WhatsappIcon from '@/assets/icons/whatsapp_icon.svg?react';
import YoutubeIcon from '@/assets/icons/youtube_icon.svg?react';
import LinkedinIcon from '@/assets/icons/linkedin_icon.svg?react';
import { StyledFormLabel } from '@/components/forms/FormLabel';

export const BoxIcon = styled(Box)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
});

type SocialsProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
};

const socialMediaPlatforms = [
  {
    label: 'Facebook',
    placeholder: 'Enter Facebook link',
    regex: /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
    icon: <FacebookIcon />,
  },
  {
    label: 'WhatsApp',
    placeholder: 'Enter WhatsApp link',
    regex: /^(https?:\/\/)?(wa\.me|whatsapp\.com)\/.+$/i,
    icon: <WhatsappIcon />,
  },
  {
    label: 'YouTube',
    placeholder: 'Enter YouTube link',
    regex: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/i,
    icon: <YoutubeIcon />,
  },
  {
    label: 'LinkedIn',
    placeholder: 'Enter LinkedIn link',
    regex: /^(https?:\/\/)?([\w]+\.)?linkedin\.com\/.+$/i,
    icon: <LinkedinIcon />,
  },
];

const FormSocials = <TSchema extends FieldValues>({
  name,
  label,
  control,
}: SocialsProps<TSchema>) => {
  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', marginBottom: '20px' }}
    >
      <StyledFormLabel>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const socialLinks = Array.isArray(value) ? value : ['', '', '', ''];

          const handleInputChange =
            (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
              const updatedLinks = [...socialLinks];
              updatedLinks[index] = e.target.value;
              onChange(updatedLinks);
            };

          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {socialMediaPlatforms.map((platform, index) => (
                <Box
                  key={platform.label}
                  sx={{
                    position: 'relative',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <FormControl sx={{ width: '100%' }}>
                    <StyledInput
                      onChange={handleInputChange(index)}
                      value={socialLinks[index]}
                      placeholder={platform.placeholder}
                      fullWidth
                      inputProps={{
                        style: { paddingLeft: '58px', paddingRight: '55px' },
                      }}
                    />
                  </FormControl>
                  <BoxIcon sx={{ left: '26px' }}>{platform.icon}</BoxIcon>
                  <>
                    {socialLinks[index] &&
                    platform.regex.test(socialLinks[index]) ? (
                      <BoxIcon sx={{ right: '20px' }}>
                        <CheckCircle />
                      </BoxIcon>
                    ) : null}
                  </>
                </Box>
              ))}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormSocials;
