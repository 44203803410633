import useDebouncedSearch from '@/hooks/useDebouncedSearch.ts';
import FormAutoSuggest from '@/components/forms/FormAutoSuggest';
import { Control } from 'react-hook-form';
import { useLocationsQuery } from '@/api/auth/queries.ts';

const LocationAutoSuggest = ({ control }: { control: Control<any> }) => {
  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch();

  const { data: locations } = useLocationsQuery(searchValue);
  return (
    <FormAutoSuggest
      name="locationId"
      control={control}
      label="Location"
      placeholder="Enter location"
      options={locations || []}
      onDebounce={handleDebounceSearchValue}
    />
  );
};

export default LocationAutoSuggest;
