import { FieldsContainer } from '@/styled';
import { useFormContext } from 'react-hook-form';
import { HirerFields } from '@/pages/RegistrationPage/components/HirerForm/index.tsx';
import FormInput from '@/components/forms/FormInput';
import FormSlider from '@/components/forms/FormSlider';
import { COMPANY_SIZE_MARKS } from '@/constants';

const FourthHirerStep = () => {
  const { control } = useFormContext<HirerFields>();

  return (
    <FieldsContainer>
      <FormInput<HirerFields>
        name="companyIndustry"
        control={control}
        label="Industry"
        placeholder={'Enter your industry'}
      />
      <FormSlider<HirerFields>
        name="companySize"
        control={control}
        label="Company Size"
        min={0}
        max={4}
        step={1}
        marks={COMPANY_SIZE_MARKS}
      />
      <FormInput<HirerFields>
        name="companyDescription"
        control={control}
        label="About Company"
        multiline
        rows={4}
        placeholder={'Enter about your company'}
      />
    </FieldsContainer>
  );
};

export default FourthHirerStep;
