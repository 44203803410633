import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserType } from '@/types/user.ts';

type State = {
  token: string | null;
  isAuth: boolean;
};

type Actions = {
  setToken: (token: string) => void;
  logout: () => void;
  role: UserType['role'] | null;
};

const initialState = {
  token: null,
  isAuth: false,
  role: null,
};

export const useAuthStore = create(
  persist<State & Actions>(
    (set) => ({
      ...initialState,
      setToken: (token) =>
        set(() => ({
          token,
          isAuth: !!token,
        })),
      logout: () => set(() => initialState),
    }),
    {
      name: 'auth',
    }
  )
);
