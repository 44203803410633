import { useFormContext } from 'react-hook-form';
import { TalentFields } from '@/pages/RegistrationPage/components/TalentForm/index.tsx';
import { FieldsContainer } from '@/styled';
import FormInput from '@/components/forms/FormInput';
import FormMediaUpload from '@/components/forms/FormMediaUpload';

const FourthTalentStep = () => {
  const { control } = useFormContext<TalentFields>();
  return (
    <FieldsContainer>
      <FormInput<TalentFields>
        name="videoUrl"
        control={control}
        label="Video Link"
        placeholder={'Enter your video link'}
      />
      <FormMediaUpload<TalentFields>
        name="mediaIds"
        control={control}
        label="Upload Photos"
      />
    </FieldsContainer>
  );
};

export default FourthTalentStep;
