import { Box, styled, Typography } from '@mui/material';
import BigLogo from '@/assets/icons/big_logo.svg?react';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '40px 70px',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));

const AuthHeader = () => {
  return (
    <StyledHeader>
      <BigLogo style={{ width: '70px', height: '70px' }} />
      <Box
        sx={{
          paddingLeft: '25px',
          borderLeft: '1px solid #fff',
          display: 'flex',
          alignItems: 'center',
          maxWidth: '320px',
          marginLeft: '15px',
        }}
      >
        <Typography sx={{ color: '#fff' }} variant={'inter30Regular'}>
          Talenver, where talent meets opportunity.
        </Typography>
      </Box>
    </StyledHeader>
  );
};

export default AuthHeader;
