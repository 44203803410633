import { useState } from 'react';

const useDebouncedSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const handleDebounceSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return { searchValue, handleDebounceSearchValue };
};

export default useDebouncedSearch;
