import { Box, styled, Typography } from '@mui/material';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import { UserType } from '@/types/user.ts';
import { getFormStepTitle } from '@/utils/getFormStepTitle.ts';

type Props = {
  selectedRole: UserType['role'];
  stepsAmount?: number;
};

const StepContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  justifyContent: 'space-between',
  marginTop: '10px',
});

const StepItem = styled(Box)<{ active: number }>(({ theme, active }) => ({
  flex: 1,
  borderRadius: '20px',
  height: '6px',
  backgroundColor: !!active ? theme.palette.purple1 : theme.palette.gray1,
}));

const FormStepComponent = ({ selectedRole, stepsAmount = 4 }: Props) => {
  const currentStep = useRegistrationStepsStore((state) => state.currentStep);
  const title = getFormStepTitle(selectedRole, currentStep);

  return (
    <Box>
      <Typography variant="poppins24Medium">{title}</Typography>
      <StepContainer>
        {[...Array(stepsAmount)].map((_, index) => (
          <StepItem
            key={index}
            sx={{
              ...(currentStep === index + 1 && { backgroundColor: 'blue1' }),
            }}
            active={Number(index + 1 < currentStep)}
          />
        ))}
      </StepContainer>
      <Typography
        sx={{ display: 'block', marginTop: '5px' }}
        variant="poppins16Regular"
      >
        {currentStep} of {stepsAmount}
      </Typography>
    </Box>
  );
};

export default FormStepComponent;
