import React, { useRef, useState } from 'react';
import { Box, IconButton, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import BigPlusIcon from '@/assets/icons/big_plus_icon.svg?react';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import RemoveButton from '@/components/common/RemoveButton';
import { styled } from '@mui/material/styles';
import { SingleFileUploadResponse } from '@/api/user/types.ts';
import { uploadFile } from '@/api/user/fetchers.ts';
import * as Sentry from '@sentry/react';
import env from '@/env.ts';

type MediaUploadProps<TSchema extends FieldValues> = {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: React.ReactNode;
  gridProps?: number;
};

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingBottom: '100%',
  borderRadius: '10px',
  height: '92px',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '10px',
});

const UploadBox = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '92px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.gray1,
  borderRadius: '10px',
  cursor: 'pointer',
}));

const FormMediaUpload = <TSchema extends FieldValues>({
  name,
  control,
  label,
  gridProps = 3,
}: MediaUploadProps<TSchema>) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = async (
    file: File,
    onChange: (value: string[]) => void,
    currentImages: string[]
  ) => {
    setLoading(true);
    try {
      const response: SingleFileUploadResponse = await uploadFile(file);
      onChange([...currentImages, response.fileId]);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <FormControl
      component="fieldset"
      sx={{ width: '100%', paddingBottom: '20px' }}
    >
      <StyledFormLabel sx={{ marginBottom: '18px' }}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Grid container spacing={'28px'}>
            {value?.map((media: string, index: number) => (
              <Grid size={{ xs: gridProps }} key={media}>
                <ImageContainer>
                  <StyledImage
                    src={`${env.VITE_AWS_STORAGE_URL}/${media}`}
                    alt={`media-${index}`}
                  />
                  <RemoveButton
                    onClick={() =>
                      onChange(
                        value.filter((_: string, i: number) => i !== index)
                      )
                    }
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </ImageContainer>
              </Grid>
            ))}
            <Grid size={{ xs: gridProps }}>
              <UploadBox onClick={handleUploadClick}>
                <input
                  type="file"
                  ref={fileInputRef}
                  hidden
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      await handleFileUpload(file, onChange, value || []);
                    }
                  }}
                />
                <BigPlusIcon />
              </UploadBox>
            </Grid>
          </Grid>
        )}
      />
    </FormControl>
  );
};

export default FormMediaUpload;
