import { Box, styled, Typography } from '@mui/material';
import { useRef } from 'react';
import EditButton from '@/components/common/EditButton';
import { uploadFile } from '@/api/user/fetchers.ts';
import * as Sentry from '@sentry/react';
import env from '@/env.ts';

export const UploadBox = styled(Box)({
  width: 100,
  height: 100,
  borderRadius: 99,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  backgroundColor: '#fafafb',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const UploadAvatar = ({
  userImg,
  onUpload,
  squared,
}: {
  userImg?: string;
  onUpload: (value: any) => void;
  squared?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const imageUrl = `${env.VITE_AWS_STORAGE_URL}/${userImg}`;

  const handleUploadedFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await uploadFile(file);
        onUpload(response?.fileId);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    }
  };
  return (
    <UploadBox
      onClick={() => {
        inputRef.current?.click();
      }}
      sx={{
        backgroundImage: `url(${imageUrl})`,
        ...(squared && {
          borderRadius: '10px',
          maxWidth: '92px',
          maxHeight: '92px',
        }),
      }}
    >
      <EditButton
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          ...(squared && { top: '-10px', right: '-10px' }),
        }}
      />
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          handleUploadedFile(e);
        }}
      />
      {!userImg && (
        <>
          <Typography>Upload</Typography>
        </>
      )}
    </UploadBox>
  );
};

export default UploadAvatar;
