import { FormLabel, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  horizontal?: boolean;
  sx?: SxProps;
};

export const StyledFormLabel = ({ children, horizontal, sx }: Props) => {
  return (
    <FormLabel component="legend">
      <Typography
        variant="poppins16Medium"
        sx={{
          ...(horizontal && { marginLeft: '20px' }),
          ...(!horizontal && { marginBottom: '10px' }),
          display: 'block',
          color: '#051320',
          ...sx,
        }}
      >
        {children}
      </Typography>
    </FormLabel>
  );
};

export default FormLabel;
